import React from "react";
import ProgContentImg from "../components/ProgContentImg";
import TextInImageTwo from "../components/textInImage__";
import ImageText from "../components/imageText";
import TextImage from "../components/textImage";
import Asd from "../components/elecproj";
import { Container } from "@material-ui/core";
import Footer from "../components/footer";



export default class LandingPage extends React.Component{
  render(){
    return(
      <div >
        <div className="backgroundOne">
        < ProgContentImg /> 
        < TextInImageTwo /> 
        </div>
        <Container>

< ImageText title="Welcome to Your Path to Top Universities" 
   imageURL="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
   text="At West Link Education, we&#39;re dedicated to helping grade 12 or college students for
   Bachelor Programs and university students for Graduate Programs in prestigious
   Universities in the World. Our project-based courses are designed to equip you with
   the skills, knowledge, and confidence you need to stand out in the competitive world of
   top university admissions." /> 
< TextImage title="Why Choose Us?" 
imageURL="https://images.unsplash.com/photo-1529070538774-1843cb3265df?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
text={
  <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
    <li className="my-list-item">Personalized Guidance from World renowned Professors</li>
    <li className="my-list-item"> Admission to top Universities</li>
    <li className="my-list-item">Increases your chance to get scholarships</li>
    <li className="my-list-item">Flexible Learning to Fit Your Schedule</li>
    <li className="my-list-item">Join the Journey to Your Dream University</li>
  </ul>
}
  />
       {/* < Asd  title="ELECTRONIC PROJECTS" 
      imageURL="https://i.imgur.com/PkRnUM3.jpg" 
      text="Learn about electronics and electricity basics Learn about multi-meter for electric measurement Learn how to use LED, micro-processor Learn how to control motors Learn in a project-based group assignment"
      readMore=" "
      />  */}

  
    
      
</Container>
    

       




    </div>


   
    )
  }

}