import React, {useState, useEffect, useRef} from 'react';
import { withRouter } from 'react-router';
import { Container } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { SentimentSatisfied } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import RegisImage from "../components/RegisImage"
import CompTextWithin from "../components/textWithinComp"
import NavigatorForm from '../components/navigatorForm'


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


  var Recaptcha = require('react-recaptcha');



const ContactForm = (props) => {



    const [open, setOpen] = useState(false);


    const buttonStyle = {
        marginTop: "20px",
        backgroundColor: "rgb(20, 150, 255)",
        outline: "none",
        padding: "10px",
        fontWeight: "500",
        color: "white"
    }

    const initialValues = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        studentSchool: '',
        notes: '',
        enrollment: [],
        preferred: []
        // module1: false,
        // module2: false,
        // module3: false,
        // module4: false,
    }


   

    const handleClick = () => {
        setOpen(true);
      };
    
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    var [values, setValues] = useState(initialValues)

    var [capchca, setCap] = useState(true);


    var [able, setAble] = useState(true);

    // const handleChange = e => {
    //     var { name, value } = e.target;
    //     setValues({
    //         ...values, 
    //         [name]: value,
    //     })




    // }
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
      
        if (type === "checkbox" && name === "enrollment") {
          setValues((prevValues) => ({
            ...prevValues,
            enrollment: checked
              ? [...prevValues.enrollment, value]
              : prevValues.enrollment.filter((item) => item !== value),
          }));
        } 
        else if (type === "checkbox" && name === "preferred") {
            setValues((prevValues) => ({
              ...prevValues,
              preferred: checked
                ? [...prevValues.preferred, value]
                : prevValues.preferred.filter((item) => item !== value),
            }));
          }
        else {
          setValues({
            ...values,
            [name]: value,
          });
        }
      };
      
    // const handleChange = (e) => {
    //     const { name, value, type, checked } = e.target;
      
    //     if (type === "checkbox" && name === "enrollment") {
    //       // Handle checkboxes for enrollment
    //       setValues((prevValues) => ({
    //         ...prevValues,
    //         enrollment: checked
    //           ? [...prevValues.enrollment, value] // Add the value to the array
    //           : prevValues.enrollment.filter((item) => item !== value), // Remove the value from the array
    //       }));
    //     } else {
    //       setValues({
    //         ...values,
    //         [name]: value,
    //       });
    //     }
    //   };
      

    var callback = function () {
        console.log('Done!!!!');
    };


    var verifyCallback = function () {
        console.log("Verified");
        setCap(false);
  

    };



    const handleSubmit = e => {
       
            const templateId = 'template_pzao8bu';
            e.preventDefault();
            handleClick();
            props.sendFeedback(templateId, {
                from_firstName: values.firstName,
                from_lastName: values.lastName,
                from_email: values.email,
                phone_number: values.phone,
                message_html: values.message, 
                // module_one: values.module1,
                enrollment_plan: values.enrollment.join(','),
                preferred_module: values.preferred.join(','),
                additional_info_notes: values.notes,
            });

            setValues({...initialValues});
            e.target.reset();
    
    }

   


    return(
        <>         
        <div  className="formArea">
            
            <Container>
                
                <form onSubmit={handleSubmit} className="formHere">
                <h4>Registration Form</h4>
                <br></br><br></br>
                  <Grid container>
                    <Grid item md={6} xs={12} className="formItem">
                        <label style={{fontWeight: "900", fontSize: "12px"}}> First Name</label>
                        <input type="text" id="fname" name="firstName" placeholder="Student's First Name..."
                            value={values.firstName}
                            onChange={handleChange}
                        />
                    </Grid >

                    <Grid item xs={6} className="formItem">
                        <label style={{fontWeight: "900", fontSize: "12px"}}> Last Name</label>
                        <input type="text" id="fname" name="lastName" placeholder="Student's Last Name..."
                            value={values.lastName}
                            onChange={handleChange}
                        />
                    </Grid >


                    <Grid item xs={6} className="formItem">
                        <label style={{fontWeight: "900", fontSize: "12px"}}> Email Address</label>
                        <input type="email" id="email" name="email" placeholder="Student's Email..."
                            value={values.email}
                            onChange={handleChange}
                        />
                     </Grid >


                    <Grid item xs={6} className="formItem">
                        <label style={{fontWeight: "900", fontSize: "12px"}}>Phone Number</label>
                        <input type="tel" id="phone" name="phone" placeholder="Phone Number..."
                            value={values.phone}
                            onChange={handleChange}
                        />
                     </Grid >
                   
                     <Grid item md={6} xs={12} className="formItem">
                     <br></br>
                        <label style={{fontWeight: "900", fontSize: "12px"}}>Choose Your Enrollment Plan:</label>
                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module1" name="enrollment" 
                            value="Machine Learning and its Application" 
                            checked={values.enrollment.includes("Machine Learning and its Application")} 
                            onChange={handleChange}/>
                            <label htmlFor="module1"  className="labelCheck">Machine Learning and its Applications</label>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module2" name="enrollment" 
                            value="Deep Learning Specialization" 
                            checked={values.enrollment.includes("Deep Learning Specialization")} 
                            onChange={handleChange} />
                            <label htmlFor="module2" className="labelCheck">Deep Learning Specialization </label>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module3" name="enrollment" 
                            value="Foundations of Data Science" 
                            checked={values.enrollment.includes("Foundations of Data Science")} 
                            onChange={handleChange}/>
                            <label htmlFor="module3" className="labelCheck">Foundations of Data Science </label>
                        </div><div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module4" name="enrollment" 
                            value="Technical Communications" 
                            checked={values.enrollment.includes("Technical Communications")} 
                            onChange={handleChange}/>
                            <label htmlFor="module4" className="labelCheck">Technical Communications</label>
                        </div><div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module5" name="enrollment" 
                            value="Fundamentals of Immersive Technologies" 
                            checked={values.enrollment.includes("Fundamentals of Immersive Technologies")} 
                            onChange={handleChange}/>
                            <label htmlFor="module5" className="labelCheck">Fundamentals of Immersive Technologies </label>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module6" name="enrollment" 
                            value="Introduction to Engineering Design" 
                            checked={values.enrollment.includes("Introduction to Engineering Design")} 
                            onChange={handleChange}/>
                            <label htmlFor="module6" className="labelCheck">Introduction to Engineering Design </label>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module7" name="enrollment" 
                            value="Finance" 
                            checked={values.enrollment.includes("Finance")} 
                            onChange={handleChange}/>
                            <label htmlFor="module7" className="labelCheck">Finance </label>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module8" name="enrollment" 
                            value="Business Administration" 
                            checked={values.enrollment.includes("Business Administration")} 
                            onChange={handleChange}/>
                            <label htmlFor="module8" className="labelCheck">Business Administration </label>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module9" name="enrollment" 
                            value="Micro and Macro Economics" 
                            checked={values.enrollment.includes("Micro and Macro Economics")} 
                            onChange={handleChange}/>
                            <label htmlFor="module9" className="labelCheck">Micro and Macro Economics </label>
                        </div>
               
                     </Grid >



                     <Grid item md={6} xs={12} className="formItem">
                        <label style={{fontWeight: "900", fontSize: "12px"}}>Preferred time and Area of interest</label>
                        <textarea type="text" cols="40" rows="4" id="notes" name="notes" placeholder="Student's Preferred time, area of interest and any additional info..."
                            value={values.notes}
                            onChange={handleChange}
                        />
                     </Grid >

                     <Grid item md={6} xs={12} className="formItem">
                     <br></br>
                        <label style={{fontWeight: "900", fontSize: "12px"}}>Check Your Preferred Modules</label>
                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module10" name="preferred"
                             value="12-Hour Contact Hours with Mini Project" 
                             checked={values.preferred.includes("12-Hour Contact Hours with Mini Project")} 
                             onChange={handleChange}/>
                            <label htmlFor="module10"  className="labelCheck">12-Hour Contact Hours with Mini Project </label>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module11" name="preferred" 
                             value="4-Month Project with Weekly Contact Hours" 
                             checked={values.preferred.includes("4-Month Project with Weekly Contact Hours")} 
                             onChange={handleChange}/>
                            <label htmlFor="module11" className="labelCheck">4-Month Project with Weekly Contact Hours </label>
                        </div>
                       
               
                     </Grid >


        
                    <Grid item xs={12} style={{padding: "20px"}}>
       


                        <Recaptcha
                            sitekey="6Lfqxu0oAAAAAH616yxJkuaqPFJjPZf5SFTotnG2"
                            render="explicit"
                            onloadCallback={callback}
                            verifyCallback={verifyCallback}
                        />
                        <br></br><br></br>
                        <Button variant="contained" disabled={(values.lastName && values.firstName && values.email && capchca == false) ? false : true} type="submit"  style={buttonStyle} >
                            Submit
                        </Button>
                        
                    </Grid>
                    </Grid>
                   
                </form >
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        Thank you for registering!
                    </Alert>
                </Snackbar>
            </Container>
        </div>
        </>

    );
}

export default withRouter(ContactForm);