import React from "react";
import ProgContentImg from "../components/ProgContentImg";
import TextInImageTwo from "../components/textInImage__";
import ImageText from "../components/imageText";
import TextImage from "../components/textImage";
import Asd from "../components/elecproj";
import { Container } from "@material-ui/core";
import Footer from "../components/footer";
import TextInObjectiveTop from "../components/textInObjectiveTop";



export default class Objective extends React.Component{
  render(){
    return(
      <div >
        <div className="backgroundOne">
        < ProgContentImg /> 
        < TextInObjectiveTop /> 
        </div>
        <Container>

< ImageText title="Skill Enhancement" 
   imageURL="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
   text="At West Link Education, our primary objectives are to empower you for North American and European University admissions success. We aim to enhance your academic, leadership, and personal skills, ensuring you're well-prepared for the challenges of top universities. 
   Top students will have an opportunity to be recruited as Teaching Assistants (TAs) and mentors for the offered courses in the future.
   " /> 
< TextImage title="Personalized Guidance" 
imageURL="https://images.unsplash.com/photo-1529070538774-1843cb3265df?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
text="Our experienced instructors offer personalized guidance to help you navigate the admissions process and make informed choices. 
TAs/mentors will be available for further guidance and advice."  />

< ImageText title="Competitive Advantage" 
   imageURL="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
   text="We provide you with a competitive edge by honing your abilities and showcasing your potential to admission committees by providing reference letters for your admission and scholarships." 
   /> 
< TextImage title="Flexible Learning" 
imageURL="https://images.unsplash.com/photo-1529070538774-1843cb3265df?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
text="We understand your busy schedule and offer flexible learning options to accommodate your needs."  />

       {/* < Asd  title="ELECTRONIC PROJECTS" 
      imageURL="https://i.imgur.com/PkRnUM3.jpg" 
      text="Learn about electronics and electricity basics Learn about multi-meter for electric measurement Learn how to use LED, micro-processor Learn how to control motors Learn in a project-based group assignment"
      readMore=" "
      />  */}

  
    
      
</Container>
    

       




    </div>


   
    )
  }

}