import React, { useState, useEffect } from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import '../stylesheets/components.css'
import logo from "../images/circle.png";
import { Link } from 'react-router-dom';


export default function Navbar(){
    const [navbar, setNavbar] = useState(false);


    const changeBack = () => {
        if (window.scrollY >= 90){
            setNavbar(true)
        }else{
            setNavbar(false)
        }
        
    }

    return(
        <Grid >
        <AppBar position="absolute" className={navbar ? 'navbar active' : 'navbar'}>
            <Container>
        <Toolbar>
        <Grid item xs={3}>
            <div style={{marginTop: '30px'}} >
                <Link to="/" className={navbar ? 'titleBlack' : 'titleWhite' }> 
                <span  style={{margin: '30px'}}>
                    <img src={logo}
                     style={{
                        width: '150x',    // Increase the width to make the image bigger
                        height: '150px',   // Increase the height to make the image bigger
                      }}
                class="logoIcon" alt="Kat-Club-Logo">
                </img>
                </span>
                {/* <span className='name'>West Link Education</span> */}
               
                
                </Link>
            </div>
            
        </Grid>
        <Grid item xs={3}>
        </Grid>

        <Grid item xs={2}>
                      
                      <div>
          
                          <li><Link to="/" className={navbar ? 'navLink black' : 'navLink'}>Home</Link></li>
          
                      </div>
                     
                  </Grid>
        <Grid item xs={2}>
                      
            <div>

                <li><Link to="/programs" className={navbar ? 'navLink black' : 'navLink'}>Programs</Link></li>

            </div>
           
        </Grid>
        <Grid item xs={2}>
                      
            <div>

                <li><Link to="/courses" className={navbar ? 'navLink black' : 'navLink'}>Courses</Link></li>

            </div>
           
        </Grid>
        <Grid item xs={2}>
                      
                      <div  >
                          
                          <li><Link to="/Objective" className={navbar ? 'navLink black' : 'navLink'}>Objective</Link></li>
                      </div>
                      
                  </Grid>

        <Grid item xs={2}>
                      
            <div  >
                
                <li><Link to="/registration/weekday" className={navbar ? 'navLink black' : 'navLink'}>Registration</Link></li>
            </div>
            
        </Grid>

        <Grid item xs={2}>
                      
            <div  >
                
                <li><Link to="/about" className={navbar ? 'navLink black' : 'navLink'}>About</Link></li>
            </div>
            
        </Grid>
        
        {/* <Grid item xs={2}>
            
            <div  >
                
                <li><Link to="/more" className={navbar ? 'navLink black' : 'navLink'}>More</Link></li>
            </div>
            
        </Grid> */}



        </Toolbar>
        </Container>
      </AppBar>
      </Grid>
    
    )
}