import './App.css';
import Landing from './views/landingPage'
import NavBar from './components/Navbar'
import Container from '@material-ui/core/Container';
import { Route, Switch } from 'react-router-dom';
import Programs from './views/programContent'
import MorePage from './views/more'
import Registration from './views/registration'
import About from './views/about';
import './stylesheets/components.css'
import Footer from './components/footer'
import ContactFormWeekend from './components/contactFormWeekend'
import MediaQuery from 'react-responsive'
import NavBarMob from './components/NabBarMob'
import Objective from './views/Objective';
import Courses from './views/courses'


function App() {
  return (
    <div className="App">
      <Container>

      <MediaQuery maxDeviceWidth={940} >
        < NavBarMob />

      </MediaQuery>
      <MediaQuery minDeviceWidth={940} >
         < NavBar />

      </MediaQuery>
    

   

       
      </Container>


      <Switch>
                <Route path="/" component={Landing} exact />
                <Route path="/programs" component={Programs} />
                <Route path="/courses" component={Courses} />
                <Route path="/registration/" component={ Registration }  />
                <Route path="/about" component={ About }  />
                <Route path="/objective" component={ Objective }  />
                <Route path="/more" component={ MorePage }  />
      </Switch>

      < Footer /> 
        
    </div>
  );
}

export default App;
