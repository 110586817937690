import React from "react";


export default function TextInObjectiveTop(){
    return(
        <>
               
        <p className="textWithin">
            Our Objectives
        </p>


        </>
    )
}