import React from "react";


export default function TextInImageTwo(){
    return(
        <>
               
        <p className="textWithin">
            Our Program 
        </p>


        </>
    )
}