import React from "react";


export default function TextInImageFour(){
    return(
        <>
               
        <p className="textWithin">
        About WestLinkEdu
        </p>


        </>
    )
}