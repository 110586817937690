import React from "react";
import AboutContentImg from "../components/aboutContentImg";
import TextInImageFour from "../components/textInImageFour";
import ImageText from "../components/imageText";
import TextImage from "../components/textImage";
import ImageTextAbout from "../components/imageTextAbout";
import Asd from "../components/elecproj";
import { Container } from "@material-ui/core";
import Footer from "../components/footer";
import anjuman from "../images/anjuman.png"
import ian from "../images/ian.png"
import jahangir from "../images/jahangir.png"
import khalad from "../images/khalad.png"
import shahria from "../images/shahriar.png"
import ying from "../images/ying.png"
export default class About extends React.Component{
  render(){
    return(
      <div >
        <div className="backgroundOne">
        < AboutContentImg /> 
        < TextInImageFour /> 
        </div>

    <Container>

    < ImageTextAbout title="WestLinkEdu" 
          imageURL="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
          text="We are West Link Education, a trusted name in university admissions preparation. Our
          team of dedicated educators and advisors are committed to helping you realize your
          dream of attending a top university."
      //     academy="Kelowna, British Columbia, Canada" 
      //     readMore="https://www.linkedin.com/in/anjuman-shahriar-p-eng-4830312b/?originalSubdomain=ca"'
      />         
    
   
    < ImageTextAbout title="Our Mission" 
         imageURL="https://images.unsplash.com/photo-1529070538774-1843cb3265df?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
           text="To provide high school/college/university students with the tools and guidance they
           need to excel in top university admissions."
        //  academy="Electrical Engineering, The University of British Columbia" 
          //readMore="https://engineering.ok.ubc.ca/about/contact/jahangir-hossain/"
          />  

    < ImageTextAbout title="Our Values" 
          imageURL="https://images.unsplash.com/photo-1517770413964-df8ca61194a6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80"
          text={
            <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
              <li className="my-list-item">Excellence in Educations</li>
              <li className="my-list-item"> Personalized Support</li>
              <li className="my-list-item">Commitment to Your Success</li>
             
            </ul>
          }
         // academy="Computer Science, Data Science, The University of British Columbia" 
          />  

 
          
    </Container>
    

       




    </div>


   
    )
  }

}