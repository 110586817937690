import React from "react";


export default function TextInImage__(){
    return(
        <>
               
        <p className="textWithin">
        Your Path to Top Universities
        </p>


        </>
    )
}