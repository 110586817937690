import React from "react";


export default function TextInImageFour(){
    return(
        <>
               
        <p className="textWithin">
        Courses
        </p>


        </>
    )
}