import React from "react";
import ProgContentImg from "../components/ProgContentImg";
import TextInImageTwo from "../components/textInImageTwo";
import ImageText from "../components/imageText";
import TextImage from "../components/textImage";
import Asd from "../components/elecproj";
import { Container } from "@material-ui/core";
import Footer from "../components/footer";


export default class ProgramContent extends React.Component{
  render(){
    return(
      <div >
        <div className="backgroundOne">
        < ProgContentImg /> 
        < TextInImageTwo /> 
        </div>

    <Container>

    < ImageText title="12-Hour Contact Hours with Mini Project" 
         // imageURL="https://images.unsplash.com/photo-1603354350317-6f7aaa5911c5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80" 
         imageURL="https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
text="This program combines intensive instruction with a hands-on project to develop the
skills and experience that top universities are looking for."/>
    < TextImage title="4-Month Project with Weekly Contact Hours" 
         // imageURL="https://images.unsplash.com/photo-1550432163-9cb326104944?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80" 
         imageURL="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
         //  text="Learn program using Arduino boards and lego-robotics. Learn control algorithm. Build your own robot and program it for activities. Train your robot for problem-solving and to adapt to challenging conditions. Get ready for ultimate robo-games." 
         text="In this program, you&#39;ll embark on a larger project while benefiting from consistent
         weekly guidance, allowing for in-depth skill development."
         />
           {/* < Asd  title="ELECTRONIC PROJECTS" 
          imageURL="https://i.imgur.com/PkRnUM3.jpg" 
          text="Learn about electronics and electricity basics Learn about multi-meter for electric measurement Learn how to use LED, micro-processor Learn how to control motors Learn in a project-based group assignment"
          readMore=" "
          />  */}
           < TextImage title="Why Choose Our Programs?" 
imageURL="https://images.unsplash.com/photo-1603796846097-bee99e4a601f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3174&q=80"
text={
  <div>
  <ul>
    <li className="my-list-item">A Proven Path to Admission Success </li>
    <li className="my-list-item">Skills that Set You Apart</li>
    <li className="my-list-item">Renowned Professors</li>
    <li className="my-list-item">Reference Letter for your admission</li>
    <li className="my-list-item">Higher chances of availing possible scholarships</li>
    <li className="my-list-item">Exclusive Resources</li>
    <li className="my-list-item">Affordable Excellence</li>
    <li className="my-list-item">Statement of purpose (SOP) and Admission Package Review
         </li>
  </ul>
  <p className="subtitleText">Interested Students are welcome to enroll (visit our registration page) and attend the
         free information session. This will be followed by a selection process considering a
         limited number of seats. Only selected students will be admitted for each course. It is
         not first come first serve basis.</p>
  </div>

}
         />

      
        
          
    </Container>
    

       




    </div>


   
    )
  }

}