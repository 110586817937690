import React from "react";
import ProgContentImg from "../components/ProgContentImg";
import TextImageCourses from "../components/TextInImageCourses";
import ImageText from "../components/imageText";
import TextImage from "../components/textImage";
import Asd from "../components/elecproj";
import { Container } from "@material-ui/core";
import Footer from "../components/footer";


export default class ProgramContent extends React.Component{
  render(){
    return(
      <div >
        <div className="backgroundOne">
        < ProgContentImg /> 
        < TextImageCourses /> 
        </div>

    <Container>

    < ImageText title="Machine Learning and its Applications" 
         // imageURL="https://images.unsplash.com/photo-1603354350317-6f7aaa5911c5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80" 
         imageURL="https://images.unsplash.com/photo-1620712943543-bcc4688e7485?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2765&q=80"
         text={
            <div>
            <ul>
              <li className="my-list-item">Equip students with a solid foundation in machine learning algorithms and techniques. </li>
              <li className="my-list-item">Provide hands-on experience in implementing machine learning models and solving real-world problems.</li>
              <li className="my-list-item">Explore the wide-ranging applications of machine learning across industries, from finance to healthcare to marketing.</li>
            </ul>
            </div>
          }

/>
    < TextImage title="Deep Learning Specialization:" 
         // imageURL="https://images.unsplash.com/photo-1550432163-9cb326104944?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80" 
         imageURL="https://images.unsplash.com/photo-1677442135703-1787eea5ce01?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2832&q=80"
         //  text="Learn program using Arduino boards and lego-robotics. Learn control algorithm. Build your own robot and program it for activities. Train your robot for problem-solving and to adapt to challenging conditions. Get ready for ultimate robo-games." 
         text={
            <div>
            <ul>
              <li className="my-list-item">Dive deep into the realm of deep neural networks and their applications. </li>
              <li className="my-list-item">Train students to design, develop, and optimize complex deep learning models.</li>
              <li className="my-list-item">Offer practical experience in working with large datasets and advanced deep learning frameworks.</li>
              <li className="my-list-item">investigate cutting-edge advancements in deep learning, such as convolutional neural networks (CNNs) and recurrent neural networks (RNNs).
</li>
            </ul>
            </div>
          }
         />
           {/* < Asd  title="ELECTRONIC PROJECTS" 
          imageURL="https://i.imgur.com/PkRnUM3.jpg" 
          text="Learn about electronics and electricity basics Learn about multi-meter for electric measurement Learn how to use LED, micro-processor Learn how to control motors Learn in a project-based group assignment"
          readMore=" "
          />  */}
             < ImageText title="Foundations of Data Science" 
         // imageURL="https://images.unsplash.com/photo-1603354350317-6f7aaa5911c5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80" 
         imageURL="https://plus.unsplash.com/premium_photo-1661270415926-37a9d24aff30?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80"
         text={
            <div>
            <ul>
              <li className="my-list-item">Provide students with essential knowledge in data analysis, and data visualization. </li>
              <li className="my-list-item">Equip students with the skills to clean, preprocess, and analyze data to derive meaningful insights.</li>
              <li className="my-list-item">Foster critical thinking and problem-solving abilities in the context of data-driven decision-making.</li>
            <li className="my-list-item">Explore various tools and technologies used in the field of data science.</li>
            </ul>
            </div>
          }

/>
           < TextImage title="Technical Communications" 
imageURL="https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80"
text={
    <div>
            <ul>
              <li className="my-list-item">Develop proficient written communication skills for technical documentation and reports. </li>
              <li className="my-list-item"> Improve oral communication abilities for effective presentations and discussions.</li>
              <li className="my-list-item">Adapt to emerging technologies in communication for enhanced effectiveness.</li>
            </ul>
            </div>

}
         />
             < ImageText title="Fundamentals of Immersive Technologies" 
         // imageURL="https://images.unsplash.com/photo-1603354350317-6f7aaa5911c5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80" 
         imageURL="https://images.unsplash.com/photo-1552871419-81ba9b1aa9c9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2952&q=80"
         text={
            <div>
            <ul>
              <li className="my-list-item">Provide students with a comprehensive understanding of various immersive technologies, including virtual reality (VR), augmented reality (AR), and mixed reality (MR) </li>
              <li className="my-list-item">Offer students practical experience by enabling them to create and interact with immersive content.</li>
              <li className="my-list-item">Teach students how to critically analyze and evaluate immersive experiences.</li>
            <li className="my-list-item"> Explore the diverse applications of immersive technologies across various industries, such as healthcare, education, entertainment, and training.</li>
            </ul>
            </div>
          }


/>
< TextImage title="Introduction to Engineering Design" 
imageURL="https://images.unsplash.com/photo-1581092160562-40aa08e78837?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80"
text={
    <div>
            <ul>
              <li className="my-list-item">Acquire a strong foundation in engineering design principles and methodologies. </li>
              <li className="my-list-item">Apply systematic problem-solving techniques to tackle engineering challenges.</li>
              <li className="my-list-item">Encourage creative thinking and innovative solutions in the design process.
</li>
            </ul>
            </div>

}
         />
      
< ImageText title="Finance" 
imageURL="https://images.unsplash.com/photo-1554224155-6726b3ff858f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2811&q=80"
text={
    <div>
            <ul>
              <li className="my-list-item">Gain a deep understanding of financial concepts, including time value of money and risk assessment. </li>
              <li className="my-list-item">Make informed investment decisions based on financial analysis and market research.</li>
              <li className="my-list-item">Develop financial strategies for optimizing returns and minimizing risks</li>
            </ul>
            </div>

}
         />
      
      < TextImage title="Business Administration" 
imageURL="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80"
text={
    <div>
            <ul>
              <li className="my-list-item">  Develop effective leadership qualities for leading diverse teams.</li>
              <li className="my-list-item">Analyze business processes and identify areas for improvement.</li>
              <li className="my-list-item">Explore entrepreneurship as a means of driving business growth and sustainability.
</li>
            </ul>
            </div>

}
         />
         
         < ImageText title="Micro and Macro Economics
" 
imageURL="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80"
text={
    <div>
            <ul>
              <li className="my-list-item">Grasp microeconomic concepts, including supply and demand, market structures, and pricing strategies. </li>
              <li className="my-list-item">Apply microeconomic theories to analyze consumer behavior and firm decisions.</li>
              <li className="my-list-item">Analyze macroeconomic factors such as GDP, inflation, and fiscal policy.</li>
            </ul>
            </div>

}
         />
    </Container>
    

       




    </div>


   
    )
  }

}